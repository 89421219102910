import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { RequestInterceptorService } from './request-interciptor.service';
import { ApiService } from './api.service';


@Injectable({ providedIn: 'root' })
export class UserService {

    bloodGroupList = [
        { name: 'O+', value: 'O_POSITIVE' }, { name: 'O-', value: 'O_NEGATIVE' }, { name: 'A+', value: 'A_POSITIVE' },
        { name: 'A-', value: 'A_NEGATIVE' }, { name: 'B+', value: 'B_POSITIVE' }, { name: 'B-', value: 'B_NEGATIVE' },
        { name: 'AB+', value: 'AB_POSITIVE' }, { name: 'AB-', value: 'AB_NEGATIVE' }
    ];

    constructor( 
        private http: HttpClient,
        private route: Router,
        private apiService: ApiService
    ) { }

    /**
     * Retrieves user information from the server.
     *
     * @return {Observable<any>} An observable that emits the user information.
    */
    getUserInfo(): Observable<any> {
        return this.apiService.httpGet(environment.ENDPOINTS.USER_PROFILE_URL);
    }


    /**
     * Retrieves the configuration for a given company ID.
     *
     * @param {string} companyId - The ID of the company for which to retrieve the configuration.
     * @return {Observable<any>} An observable that emits the configuration data.
    */
    getConfig(companyId):Observable <any> {
        return this.apiService.httpGet(environment.ENDPOINTS.CONFIG_URL+`${companyId}/config`)
    }
}